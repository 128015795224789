<template>
  <div id="bills-receive-tab">
    <div class="pt-container">
      <div class="pt-header">
        <div class="pt-title">
          Contas a receber
          <span id="help-icon" class="icon-box no-print">
            <v-help-circle class="icon stroke" />
          </span>
          <b-tooltip target="help-icon" placement="bottom">
            Crie e gerencie contas a receber, visualize datas, categorias, descrições e valores.
            Selecione as contas que deseja e clique no botão “nova entrada” para gerar nova conta a receber.
          </b-tooltip>
        </div>

        <div class="gap-action-buttons no-print">

          <Loading :class="{ reload: true, loading: loading }" @click="() => !loading && getBillsToReceive()"/>

          <b-button
            v-if="selected?.length"
            @click="openBillsToReceiveModal"
            class="wh-button"
            variant="outline-primary"
            outline
          >
            Receber selecionado(s)
          </b-button>

          <b-button class="wh-button"
            variant="outline-primary"
            outline
            @click="getXlsx"
          >
            Download xlsx
          </b-button>
        </div>
      </div>

      <div class="row no-print">
        <div class="col-4">
          <b-form-group class="form-group">
            <label for="search" id="input-title">Nome do procedimento / produto</label>
            <b-form-input
              autocomplete="off"
              id="search"
              debounce="500"
              v-model="query"
              placeholder="Buscar por procedimento ou produto"
            />
          </b-form-group>
        </div>

        <div class="col-3">
          <b-form-group id="field_category">
            <label for="field_category" id="input-title">Categoria</label>
            <v-autocomplete
              v-model="category"
              :options="categories"
              placeholder="Selecionar"
            />
          </b-form-group>
        </div>

        <div class="col-2">
          <b-form-group class="form-group">
            <label for="search" id="input-title">Situação</label>
            <v-autocomplete
              v-model="status_payment"
              :options="status_payments"
              placeholder="Selecionar"
            />
          </b-form-group>
        </div>

        <div class="col-3">

          <b-form-group class="form-group">
            <div class="label">
              <label for="search" id="input-title">Período</label>

              <div class="status">
                <div class="status-input"><input type="radio" name="status" value="due_date" v-model="periodStatus">Receb.</div>
                <div class="status-input"><input type="radio" name="status" value="payment_date" v-model="periodStatus">Pag.</div>
                <div class="status-input"><input type="radio" name="status" value="service_date" v-model="periodStatus">Atend.</div>
                <div class="status-input">
                  <SwapVertical
                    class="order-icon"
                    @click="periodOrder === 'desc' ? (periodOrder = 'asc') : (periodOrder = 'desc')"
                  />
                </div>
              </div>
            </div>

            <v-periods
              :daySelected="daySelected"
              @select="handlePeriod"
            />
          </b-form-group>
        </div>
      </div>

      <div class="row no-print">
        <div class="col-3">
          <b-form-group id="field_criator">
            <label for="criator">
              Responsável
            </label>
            <v-autocomplete
              id="criator"
              placeholder="Selecionar"
              debounce="500"
              v-model="creator_id"
              :options="creators.map(p => ({ label: p.name, value: p.id }))"
            />
          </b-form-group>
        </div>

        <div class="col-3">
          <v-beneficiaries-select
            v-model="beneficiary"
            :value="beneficiary"
            label="Favorecido"
          />
        </div>

        <div class="col-2">
          <v-patient-input v-model="patient" />
        </div>

        <div class="col-2">
          <b-form-group class="form-group">
            <label for="search" id="input-title">Pagamento</label>
            <v-autocomplete
              v-model="payment_method"
              :options="payment_methods"
              placeholder="Selecionar"
            />
          </b-form-group>
        </div>

        <div class="col-2">
          <b-form-group id="field_bank_account">
            <label for="field_bank_account" id="input-title">
              Conta bancária
            </label>
            <v-autocomplete
              v-model="bank_account"
              :options="bank_accounts"
              placeholder="Selecionar"
            />
          </b-form-group>
        </div>
      </div>

      <v-bills-table
        :bills="billsToReceive"
        :onEdit="onEditBillsToReceive"
        type="bills_to_receive"
        @selected-bills="selectedBills"
        :selectedInstallments="selected"
        :getBills="getBillsToReceive"
      />

      <div class="amount-position">
        <div>
          <p> Valor total
            <span>
              R${{ parseNumberToMoney(sumAllInstallmentAmount).trim().split('$')[1]}}
            </span>
          </p>
        </div>
        <div>
          <p> Valor pág. {{this.page}} de {{this.last_page}}
            <span>
              R${{ parseNumberToMoney(getTotalAMount()).trim().split('$')[1]}}
            </span>
          </p>
        </div>
      </div>

      <div class="pagination-position no-print">
        <div>
          <b-button
            @click="openBillsToReceiveModal"
            v-if="selected?.length"
            class="wh-button"
            variant="outline-primary"
            outline
          >
            Receber selecionado(s)
          </b-button>
        </div>

        <b-pagination
          size="sm"
          v-model="page"
          :total-rows="count"
          :per-page="limit"
          first-number
          last-number
        />
      </div>

      <v-selected-bills-to-receive-modal
        :selectedInstallments="selected"
        :onEdit="onEditBillsToReceive"
        :getBillsToReceive="getBillsToReceive"
        @onHide="onHideSelectedReceiveModal"
      />
    </div>

  </div>
</template>

<script>
import { EventBus } from '@/utils/eventBus';
import { saveAs } from 'file-saver';
import { parseNumberToMoney } from '@/utils/moneyHelper'

export default {
  name: 'bills-to-receive-tab',
  props: {
    onEditBillsToReceive: Function,
  },
  components: {
    Loading: () => import('@/assets/icons/loading.svg'),
    SwapVertical: () => import('@/assets/icons/swap-vertical.svg'),
    'v-periods': () => import('@/components/General/Periods'),
    'v-help-circle': () => import('@/assets/icons/help-circle.svg'),
    'v-autocomplete': () => import('@/components/Autocomplete'),
    'v-patient-input': () => import('@/components/General/PatientInput'),
    'v-beneficiaries-select': () => import('@/components/General/BeneficiarySelect'),
    'v-selected-bills-to-receive-modal': () => import('@/modules/financial/modals/SelectedBillsToReceiveModal'),
    'v-bills-table': () => import('@/modules/financial/components/BillsTable'),
  },

  mounted () {
    if (this.$route.query.p === 'h') {
      const now = { start: this.moment().format('YYYY-MM-DD'), end: this.moment().format('YYYY-MM-DD') };
      this.handlePeriod(now)
      this.daySelected = true
    }
    this.getBillsToReceive();
    this.getCategories();
    this.getCreators();
    this.getPaymentsMethods();
    this.getBankAccounts();
    EventBus.$on('reloadBillsToReceive', () => this.getBillsToReceive(true));
  },

  data() {
    const clinic = JSON.parse(localStorage.getItem('clinic'));
    return {
      loading: false,
      selected: [],
      periodStatus: 'due_date',
      periodOrder: 'asc',
      billToReceive: null,
      query: '',
      page: 1,
      limit: 0,
      count: 0,
      clinicId: clinic.id,
      billsToReceive: [],
      category: null,
      categories: [],
      status_payment: null,
      start: null,
      end: null,
      beneficiary: null,
      professional: null,
      creators: [],
      creator_id: null,
      patient: null,
      patients: [],
      payment_method: null,
      payment_methods: [],
      bank_account: null,
      bank_accounts: [],
      sumInstallmentAmountOfPage: 0,
      sumAllInstallmentAmount: 0,
      last_page: 0,

      status_payments: [
        { label: 'Vencido', value: 'expired' },
        { label: 'Pago', value: 'paid_out' },
        { label: 'Pendente', value: 'pending' },
        { label: 'Parcelado', value: 'installments' },
      ],
      daySelected: false
    }
  },

  methods: {
    parseNumberToMoney,
    openBillsToReceiveModal() {
      this.$bvModal.show('selected-bills-to-receive-modal')
    },
    getTotalAMount () {
      let total = 0
      if(!this.billsToReceive?.length) return 0
      
      this.billsToReceive.forEach(bill => {
        return bill.payments.forEach(payment => {
          if(payment.payment_method.name !== 'Crédito do paciente'){
            total += payment.amount
          }
        });
      })
      return total
    },
    onHideSelectedReceiveModal() {
      this.selected = [];
      this.getBillsToReceive(true);
    },
    selectedBills(billsToPay, billsToReceive) {
      if (!this.selected?.length) {
        this.selected = billsToReceive || []
        return
      }
      const aux = this.selected.concat(billsToReceive)
      this.selected = aux.filter((item, index) => aux.indexOf(item) === index)
    },
    async getBillsToReceive () {
      this.loading = true
      try {
        const filters = {
          type: 'bill_to_receive',
          item: this.query?.length ? this.query : null,
          category: this.category,
          creator_id: this.creator_id,
          status_payment: this.status_payment,
          start: this.start,
          end: this.end,
          beneficiary: this.beneficiary?.id,
          professional: this.professional?.id,
          patient: this.patient?.id,
          payment_method: this.payment_method,
          bank_account: this.bank_account,
          periodStatus: this.periodStatus,
          periodOrder: this.periodOrder,
        }

        const res = await this.api.getClinicBills(this.clinicId, this.page, filters)

        this.last_page = res.data.last_page
        this.billsToReceive = res.data.data;
        this.count = res.data.total;
        this.limit = res.data.per_page;
        this.sumAllInstallmentAmount = res.data.sum

      } catch (err) {
        this.$toast.error(err.message)
      } finally {
        this.loading = false
      }
    },
    getCategories() {
      this.api.getCategories(this.clinicId, 'Entrada', null, true)
      .then(res => {
        this.categories = res.data.map(el => ({ ...el, label: `${el.name}`, value: `${el.id}` }));
      });
    },
    getCreators() {
      this.api.showClinicProfessionals(this.clinicId)
      .then(res => {
        this.creators = res.data.map(el => ({ ...el, label: `${el.name}`, value: `${el.id}` }));
      });
    },
    getPaymentsMethods() {
      this.api.getPaymentMethodsBillToReceive(this.clinicId)
      .then(res => {
        this.payment_methods = res.data.map(el => ({ ...el, label: `${el.name}`, value: `${el.id}` }));
      });
    },
    getBankAccounts() {
      this.api.getBankAccountBillToReceive(this.clinicId)
      .then(res => {
        this.bank_accounts = res.data.map(el => ({ ...el, label: `${el.name}`, value: `${el.id}` }));
      });
    },
    handlePeriod(value) {
      this.start = value.start;
      this.end = value.end;
    },
    getXlsx() {
      const isLoading = this.$loading.show();
      this.api.getBillsToReceiveExport(
        this.clinicId,
        {
          item: this.query?.length ? this.query : null,
          category: this.category,
          status_payment: this.status_payment,
          start: this.start,
          end: this.end,
          beneficiary: this.beneficiary?.id,
          professional: this.professional?.id,
          patient: this.patient?.id,
          payment_method: this.payment_method,
          bank_account: this.bank_account,
          periodStatus: this.periodStatus,
          periodOrder: this.periodOrder,
        }
      )
      .then(res => {
        saveAs(
          new Blob([res.data], {
            type: 'application/vnd.ms-excel',
          }),
          'contas_a_receber.xlsx'
        );
      })
      .catch(err => this.$toast.error(err.message))
      .finally(() => isLoading.hide());
    },
  },

  watch: {
    page: function() { this.getBillsToReceive(); },
    query: function() { this.getBillsToReceive(); },
    category: function() { this.getBillsToReceive(); },
    creator_id: function() { this.getBillsToReceive(); },
    status_payment: function() { this.getBillsToReceive(); },
    beneficiary: function() { this.getBillsToReceive(); },
    patient: function() { this.getBillsToReceive(); },
    payment_method: function() { this.getBillsToReceive(); },
    bank_account: function() { this.getBillsToReceive(); },
    start: function() { this.getBillsToReceive(); },
    period: function() {
      this.start = this.period.start
      this.end = this.period.end
      this.getBillsToReceive();
    },
    periodStatus: function() { this.getBillsToReceive(); },
    periodOrder: function() { this.getBillsToReceive(); },
  }
}
</script>

<style scoped lang="scss">
  #bills-receive-tab {
    .pt-container {
      padding: 30px 10px;

      .pt-header {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 20px 0 30px 0;

        .gap-action-buttons {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 10px;
        }
      }

      .pt-title {
        display: inline-flex;
        align-items: center;
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: #525C7A;
      }

      .icon {
        width: 25px;
        margin-left: 5px;
        stroke: #7F91D2;
      }

      .pagination-position {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        padding: 20px 0 !important;
      }

      .amount-position {
        display: flex;
        flex-direction: row-reverse;

        div {
          padding: 8px 16px;
          background: var(--blue-100);
          border-radius: 8px;
          margin-left: 10px;
          justify-content: center;

          p {
            font-size: 16px;
            line-height: 150%;
            color: var(--type-active);

            span {
              font-weight: 700;
              color: var(--blue-500);
            }
          }
        }
      }

      label {
        width: 100%;
        font-family: 'Nunito Sans';
        font-weight: 700;
        font-size: 16px;
        color: #525C7A;
        text-align: left !important;
        color: var(--dark-blue);
        margin-bottom: 4px;
      }

      .label {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: minmax(auto, 24px);

        .status {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 8px;

          .status-input {
            font-size: 12px;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 4px;
          }
        }
      }
    }

    .wh-button {
      color: var(--blue-500);
    }
  }

  #input-title {
    font-family: 'Nunito Sans' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 150% !important;
    color: #525C7A !important;
  }
  .order-icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
    fill: var(--type-active);
  }
</style>
